@import '../../../assets/javascripts/react/constants/variables.scss';

#ck_fu, .ck-fu {
  color: $white;
  font-size: 13px;
  padding: 5px 0;
  margin: 0;
  text-align: center;
  font-family: Helvetica, sans-serif;
  width: 100%;
  line-height: 1em;

  &.demo {
    background-color: indigo;
  }
}

#ck_fu a {
  color: $white;
}

.staging {
  background: blue;
}

.qe {
  background: #BF5700;
}

.development {
  background: green;
}

.production {
  background: red;
}

.ck-fu-buffer {
  height: 23px;
}

@media print {
  #ck_fu, .ck-fu-buffer, .ck-fu {
    display: none;
  }
}

.ck-fu-impersonation {
  text-align: center;
  color: white;
  font-size: 28px;
  padding: 1em;
  background-color: red;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.ck-fu-sign-out {
  font-size: 24px;
}

.ck-fu-impersonated-user {
  font-weight: bold;
}
