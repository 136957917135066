/* _form_responsive.scss

  Makes bootstrap forms responsive.

*/

.form-responsive {
  .form-errors {
    @include make-col(24);
    @include media-breakpoint-up(md) {
      @include make-col(18);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(15);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(12);
    }
  }

  .col-form-label{
    @include make-col(24);
    text-align: left;
    @include media-breakpoint-up(md) {
      text-align: right;
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(5);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }
  }

  .row {
    >.field_with_errors {
      @include make-col(24);
      text-align: left;
      @include media-breakpoint-up(md) {
        text-align: right;
        @include make-col(6);
      }
      @include media-breakpoint-up(lg) {
        @include make-col(5);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }
      
      .col-form-label {
        flex:auto;
        width:100%;
      }
    }
  }

  .form-control-container {
    @include make-col(24);
    @include media-breakpoint-up(md) {
      @include make-col(12);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(8);
    }
  }

  .form-actions {
    @include make-col-offset(0);
    @include make-col(24);
    @include media-breakpoint-up(md) {
      @include make-col-offset(6);
      @include make-col(12);
    }
    @include media-breakpoint-up(lg) {
      @include make-col-offset(5);
    }
    @include media-breakpoint-up(xl) {
      @include make-col-offset(4);
    }
  }
}