/* base.scss

  Base styles for the parent portal.

  A Base rule is applied to an element using an element selector, a descendant selector, or a child selector, along with
  any pseudo-classes. It doesn’t include any class or ID selectors. It is defining the default styling for how that
  element should look in all occurrences on the page.
*/

@import '../../components/ck_fu';

@viewport {
  zoom: 1.0;
  width: extend-to-zoom;
}
@-ms-viewport {
  zoom: 1.0;
  width: extend-to-zoom;
}

body {
  background-color: $blue19;
}

body, html {
  -webkit-font-smoothing: antialiased;
}

/* LAYOUT GLOBALS */
h1 {
  color: $teal2;
  font-size: 21px;
  font-weight: bold;
}

h2 {
  color: $teal2;;
  font-size: 18px;
  font-weight: bold;
}

header {
  @include clearfix();

  margin-bottom: -1px; // Fixes seam lines on Safari on iOS

  .header-text > h1 {
    margin-bottom: 18px;
  }
}

.explanation-text {
  font-style: italic;
  color: $gray1;
}

.inline-radio {
  display: inline-block;
  margin-left: 5px;

  > label {
    margin-left: 10px;
    font-weight: normal;
  }

  > input[type="radio"] {
    margin: 0 3px 0;
    padding: 0;
  }
}

.bg-success {
  background-color: $green16;
}