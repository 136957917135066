// Colors
$orange: #E86810;
$orange1: #cc8888;
$orange2: #f89406;
$orange3: #F99319;
$orange4: #FCAE3D;
$orange5: #CE8483;
$orange6: #f8ebb9;
$orange7: #B95B00;
$orange8: #E97403;
$orange9: #D3824C;

$teal0: #3FBAB6;
$teal1: #E8FCFC;
$teal2: #117A8D;
$teal3: #0f5f6d;

$gray0: #4A4A4A;
$gray1: #7A7A7A;
$gray2: #A3A3A3;
$gray3: #D3D3D3;
$gray4: #E8E8E8;
$gray5: #F9F9F9;
$gray6: #656567;
$gray7: #E0DCD7;
$gray8: #595959;
$gray9: #555556;
$gray10: #F4F4F4;
$gray11: #aaaaaa;
$gray12: #dddddd;
$gray13: #f8f7f5;
$gray14: #999999;
$gray15: #efefef;
$gray16: #cccccc;
$gray17: #999999;
$gray18: #888888;
$gray19: #E3E3E3;
$gray20: #BBBBBB;
$gray21: #DDDDDD;
$gray22: #BDBDBD;
$gray23: #9B9B9B;
$gray24: #DFDFDF;
$gray25: #CFCFCF;
$gray26: #7B7B7B;
$gray27: #6F6F6F;
$gray28: #F5F5F5;
$gray29: #F0F5FA;
$gray30: #757575;
$gray31: #494542;
$gray32: #979797;
$gray33: #FCF3ED;
$gray34: #e2e2e2;
$gray35: #777777;
$gray36: #EEEEEE;
$gray37: #F6F7F8;
$gray38: #E5E5E5;
$gray39: #DEE2E6;
$gray40: #666666;
$gray41: #FCFCFC;
$gray42: #F7E9E2;
$gray43: #F9F3E1;
$gray44: #ECE7F2;
$gray45: #E4EEEF;
$gray46: #DAEDD6;
$gray47: #E2F2F2;
$gray48: #7F7B78;
$gray49: #f2efeb;
$gray50: #616F80;
$gray51: #707070;
$gray52: #EBFCFA;
$gray53: #808080;
$gray54: #b9bdbc;
$gray55: #6d6e71;
$gray56: #58595b;
$gray57: #e6e7e8;
$gray58: #939598;
$gray59: #414042;
$gray60: #676767;
$gray61: #E0E0E0;
$gray62: #F2F8F9;
$gray63: #F0FCFC;
$gray64: #ECEAE7;
$gray65: #DDDDDC;
$gray66: #494949;
$gray67: #6C6864;
$gray68: #555555;
$gray69: #AAA7A3;
$gray70: #f2f2f2;
$gray71: #7B7874;
$gray72: #747476;
$gray73: #95959A;
$gray74: #ced4da; // This is the same border color as bootstrap 5 form-control borders
$gray75: #F8F9FA; // bootstrap bg-light
$gray76: #E7E8EC;
$gray77: #212529; // Bootstrap 5 table font color
$gray78: #716b66; //help-block text color
$gray79: #434343;

$blue0: #01497F;
$blue1: #145DA9;
$blue2: #218CDB;
$blue3: #8FD3F9;
$blue4: #CDECF9;
$blue5: #E0F9FC;
$blue6: #337ab7;
$blue7: #A7D5F1;
$blue8: #197CB7;
$blue9: #63D7D9;
$blue10: #105C89;
$blue11: #82BED1;
$blue12: #0E5A72;
$blue13: #0081C2;
$blue14: #115092;
$blue15: #0B439D;
$blue16: #0099CC;
$blue17: #067AB4;
$blue18: #0b7cb9;
$blue19: #0067AC;
$blue20: #163052;
$blue21: #295998;
$blue22: #346FBD;
$blue23: #428FF3;

$green0: #43663D;
$green1: #4D8E3F;
$green2: #5BB35B;
$green3: #B1DDA2;
$green4: #D7EDCE;
$green5: #E8F7E4;
$green6: #298460;
$green7: #94C03D;
$green8: #77B56D;
$green9: #8BB58B;
$green10: #5D9354;
$green11: #547a4c;
$green12: #96C13D;
$green13: #71AE43;
$green14: #0C3765;
$green15: #cdffd8;
$green16: #96BE83;
$green17: #50B1B2;

$red0: #930A18;
$red1: #B4121B;
$red2: #D84848;
$red3: #F9C8B7;
$red4: #FCECE3;
$red5: #FFF7F3;
$red6: #E69497;
$red7: #C83EAB;
$red8: #CE3C3C;
$red9: #E26479;
$red10: #f00000;
$red11: #B94A48;
$red12: #D0021B;
$red13: #D77107;
$red14: #BF5700;
$red15: #843534;
$red16: #C63E4B;
$red17: #ea6800;
$red18: #E25A30;

$yellow0: #F7A006;
$yellow1: #FFC354;
$yellow2: #FFD26E;
$yellow3: #F9E4A0;
$yellow4: #FCF2CA;
$yellow5: #FFF8DC;
$yellow6: #F8EEB9;

$white: #FFFFFF;
$black: #000000;
$black1: #231F20;
$black2: #333333;
$black3: #121213;

$purple0: #531A51;

$pink0: #ffdce0;
$pink1: #ffdbd6;

$indigo0: #4b0082;
