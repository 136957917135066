/* _bootstrap_loader.scss

  Sets customized bootstrap variables, loads bootstrap, loads font awesome, and sets up and loads fonts.
  Load bootstrap functions and variables before overriding variables
*/
@import './bootstrap_variables';

@import '~bootstrap/scss/bootstrap';
@import '~font-awesome/scss/font-awesome';

.form-select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
