.session-timeout-warning {
  text-align: center;
  background: $red0;
  color: white;

  .session-timeout-warning-header {
    font-size: 20pt;
    margin-bottom: 10px;
  }

  a {
    color: white;
  }

  padding: 15px;
}