/* _page_header.scss

  Header bar that has the "account" and "sign out" links on most pages.

*/

.pb-2.mt-4.mb-2.border-bottom h1 {  /* Replaced .page-header class - removed in Bootstrap 4*/
  margin-top: 0;
}

.pb-2.mt-4.mb-2.border-bottom {  /* Replaced .page-header class - removed in Bootstrap 4*/
  @include make-row();
  margin-top: 0;
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;

  @include media-breakpoint-down(md) {
    h1 {
      line-height: 1.3;
      margin-top: 15px;
    }
  }
}

.parent-portal-header {
  line-height: 25px;
  text-align: center;

  .parent-portal-login-description {
    text-align: left;
  }

  h1 {
    color:$black3;
    font-weight:300;
    font-size:32px;
  }

  h2 {
    color: $black3;
    font-weight: 300;
    font-size: 22px;
  }
}