.maintenance-warning {
  text-align: center;
  background: $gray4;
  color: $gray0;
  line-height: 25px;

  .fa-stack {
    line-height: 25px;

    i.fa-circle {
      color: $orange;
      font-size: 1.5em;
    }

    i.fa-wrench {
      color: $white;
      font-size: 1em;
    }
  }
}