/* _sign_in.scss

  Sign in widget.

*/

.sign-in,
.create-account,
.forgot-password,
.admin-login {
  width: 450px;
  margin: auto;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  header {
    margin-bottom: 1rem;

    h1 {
      color: $black;
      text-align: center;
      font-size: 22px;
      line-height: 25px;
      font-weight: 300;
    }

    .login-page-description {
      text-align: left;
    }
  }

  .form-group {
    margin-bottom: 7px;
  }

  .show-hide-password {
    cursor: pointer;
    float: right;
    margin-top: -30px;
    margin-right: 12px;
    position: relative;
    color: $gray73;
  }

  .forgot-password-link {
    text-align: end;
  }

  .form-actions {
    margin-top: 1em;
    display: grid;
  }

  .login-options {
    margin-top: 20px;
    margin-bottom: 20px;

    .login-option-separator {
      text-align: center;
      border-bottom: 1px solid $gray6;
      line-height: 0.1em;
      margin: 20px 0;

      span {
        background-color: $white;
        padding: 0 10px;
      }
    }

    .login-option {
      display: grid;
    }
  }
}

.school-admin-sign-in-logo {
  margin: 20px auto;
  width: 175px;
  height: 65px;

  img {
    width: 100%;
    border: none;
  }
}

.login-buttons {
  display: inline;
}

.sign-in-google {
  background-color: transparent;
  border-radius: 0;
  box-sizing: content-box;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  padding: 0;
  text-align: start;

  img {
    width: 191px;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.authentication-code {
  .row {
    margin-bottom: 15px;
  }
}
