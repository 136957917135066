/* _authentication.scss

  Parent portal authentication code widget.

*/

.authentication-verification-radios {
  @extend .card;

  display: inline-block;
  margin: 3px 0 5px 0;
  padding: 9px 9px 5px 9px;
}

.authentication-help-text {
  display: inline-block;
  margin: 0 0 0 5px;
  color: $gray3;
  font-style: italic;
}

.authentication-custom-text {
  @include make-col(14);
}

.login-box {
  width: 450px;
  margin: auto;

  .form-horizontal {
    .control-label {
      margin-top: 8px;
    }
    .controls {
      input {
        margin-top: 8px;
      }

      .hint {
        margin-top: 4px;
        margin-bottom: 8px;
        padding-left: 4px;
        font-size: 0.7rem;
        font-style: italic;
      }

      .change-password-button {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .shared-links {
    margin-top: 8px;
    text-align: end;
  }
}