/* _section.scss

  Styling for sections. These are used in the parent portal and on authentication pages to separate each widget.

*/

section {
  @include media-breakpoint-down(md) {
    &:not(:last-of-type) {
      margin-bottom: 40px;
    }

    &:first-of-type {
      margin-top: 15px;
    }
  }
}

section h2:first-of-type {
  border-bottom: 1px solid $teal2;
  padding-bottom: 4px;
  margin: 8px 0 13px;
}

section h2.no-style:first-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

// For embedding things on the right side of the section header
.section-header-right {
  float: right;

  .progress {
    width: 250px;
    margin: 5px 0 0;
  }
}

@include media-breakpoint-up(lg) {
  .section-full .form-responsive .form-control-container {
    @include make-col(8);
  }

  .section-full {
    @include make-col(24);
  }
}
