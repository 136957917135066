@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import "~bootstrap/scss/maps";
@import '~bootstrap/scss/mixins';
@import '../../../javascripts/react/constants/variables';

/*
 *  Colors
 */
$primary:       $teal2;
$secondary:     $gray8;

$custom-colors: (
  primary:   $primary,
  secondary: $secondary
);

$theme-colors:  map-merge($theme-colors, $custom-colors);



/*
 *  Typography
 */
$link-color:           $teal2;
$link-hover-color:     shift-color($link-color, $link-shade-percentage);
$badge-font-size:      0.75rem;    // badge-font-size relative to font-size-base
$body-color:           $gray31;
$btn-link-color:       $link-color;
$btn-link-hover-color: $link-hover-color;
$font-family-base:     'Lato', 'trebuchet ms', arial;
$font-size-root:       16px;
$font-size-base:       0.8125rem;  // 0.8125rem is equivalent to 13px
$small-font-size:      0.875rem;   // small font size relative to font-size-base




/*
 * Buttons + Forms
 */
$btn-font-size:                           $font-size-base;
$input-btn-font-size:                     $font-size-base;
$form-check-input-checked-bg-color:       $primary;
$form-check-input-checked-border-color:   $primary;
$form-check-input-indeterminate-bg-color: $primary;


/*
 *  Links
 */
$link-decoration: none;



/*
 *  Headings
 */
$h1-font-size:         $font-size-root * 1.5;
$h2-font-size:         $font-size-root * 1.125;
$h3-font-size:         $font-size-root * 1;
$h4-font-size:         $font-size-root * 1;
$h5-font-size:         $font-size-root * 1;

$headings-font-weight: 700;



/*
 *  Grid
 */
$grid-columns:      24;
$grid-gutter-width: 30px;



/*
 *  Modals
 */
$modal-md: 600px;

/*
 *  Navs
 */
$nav-link-color:           $link-color;
$nav-link-hover-color:     $link-hover-color;
$nav-pills-link-active-bg: $teal2;

/*
 *  Pagination
 */

$pagination-color:               $link-color;
$pagination-focus-color:         $link-hover-color;
$pagination-hover-color:         $link-hover-color;
$pagination-active-bg:           $link-color;
$pagination-active-border-color: $pagination-active-bg;
