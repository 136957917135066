/* _header.scss

  Layout style that sets up a customer's parent-facing header image.

*/

@media print {
  header {
    display: none;
  }
}


.header-image {
  overflow: hidden;

  @include media-breakpoint-down(md) {
    display: none;
  }

  img {
    margin-bottom: 1px;
    max-width: 100%;
  }
}

.logo-padding {
  padding-top: 20px;
  padding-bottom: 20px;
//  padding: 20px 0 20px 0;
}

header .navbar .navbar-inner .nav > li{
  line-height: 15px;
}
