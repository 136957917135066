/* _footer.scss

  Sets up the footer that is shared between the parent portal, authentication pages, and forms.

*/

footer {
  border-top: 1px solid $gray76;
  font-size: 10px;
  line-height: 15px;
  width:100%;
  padding: 25px 40px;
  background-color: white;
  color: $gray8;

  a {
    text-decoration: underline;
  }

  @include media-breakpoint-down(sm) {
    padding: 25px 5px;
  }

  @media print {
    display: none;
  }

  .footer-container {
    max-width:450px;
    margin:auto;

    .footer-privacy,
    .footer-rights {
      display:block;
      width:100%;
      text-align:center;
    }

    .footer-rights {
      margin-top:6px;
      color:$gray8;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .footer-logo {
        order: 1;
        margin-bottom: 15px;

        img {
          width: 65px;
        }
      }
      .footer-rights {
        order: 2;
        margin-bottom: 5px;
      }
      .footer-privacy {
        order: 3;
      }
    }
  }

  .trademark-section {
    text-align:right;

    .rights-reserved {
      margin-top:3px;
    }
  }

  .consent-preferences {
    text-align: right;
  }
}
