/* _browser_incompatible.scss

  Displayed when the user is using an incompatible browser.

*/

.browser-incompatible {
  @include media-breakpoint-up(lg) {
    @include make-col(24);
  }
}