/* _body.scss

  Layout style that sets up the body container.

*/

#content-body {
  background-color: $white;
  padding: 20px;

  @media not print {
    border-top: 4px solid transparent;
  }
}

main {
  /* backport-start */
  /* This is a backport from Bootstrap3 @include clearfix to contain child-element margins */
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
  /* backport-end */
}

#page {
  max-width: 1440px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;

  @include media-breakpoint-down(sm) {
  }

  @include media-breakpoint-up(lg) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @include media-breakpoint-up(xxl) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
